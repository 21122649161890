import { LoginStatusKind } from "../Authentication/AuthEntities";
import { FeatureFlags } from "../../Config/FeatureFlags";
import { PaymentOptionKind } from "../Payment/PaymentEntities";
import { IsDVAUser } from "../BookingTemplate/BookingTemplateHelper";
import { DateTime } from "luxon";
import { ApplicationState } from "../../appState";
import { VehicleFareDetail } from "../../Services/FixedFareEntities";

/**
 * The basic idea is to ignore small changes in times and consider them the same, for the purposes of getting a new fare, at least.
 */
export function RoundTime(rawTime: DateTime): DateTime {
    return rawTime.set({
        second: 0,
        minute: 0,
    });
};

/** 
 *  Decide if the user can book with Fixed fare based on different criteria. 
 *  WARNING: the FixedFareWithCash feature flag is always true, so this method will always return true.
 *  This seems weird given how much apparent business logic it has. Should we just get rid of it?
 */
export function CanUserMakeFixedFareBookings(currentState: ApplicationState) {
    const selectedAccount = currentState.booking.AccountData?.SelectedAccount;

    // Fixed fare booking with cash is allowed. Hence no need further checking.
    if (FeatureFlags.FixedFareWithCash) return true;

    // Guest user
    if (currentState.authentication.LoginStatus === LoginStatusKind.LoggedOut) return false;

    // DVA user
    if (IsDVAUser(currentState.authentication.UserProfile)) return false;

    // Book with account. But the selected account has no permission to book with fixed price.
    if (selectedAccount && !selectedAccount.AllowFixedPriceBookings) return false;

    // Book with account, PG bookings on account is disabled.
    if (selectedAccount && !FeatureFlags.PriceGuaranteeWithAccount) return false;

    // Book on account
    if (selectedAccount) return true;

    // Signed in user, but no cards added in the profile
    if (currentState.payment.AllCards.length === 0) return false;

    // Payment card option is not selected
    if (currentState.booking.PaymentOption?.Kind !== PaymentOptionKind.Card) return false;

    return true;
}

/**
 * Generates the display text for a fare estimate.
 * Fixed fare (i.e. Price Guarantee) is an exact amount, while metered rate is a range.
 */
export function GetFareDisplayText(fare: VehicleFareDetail, isFixedFare: boolean, doesPaymentMethodSupportPgFee: boolean) {

    let exactAmountCents = fare.FixedFareBreakdown.TotalAmount;

    // PG Fee non-applicable cases
    if ((isFixedFare == false) || (doesPaymentMethodSupportPgFee == false)) {
        exactAmountCents -= fare.FixedFareBreakdown.PgFeeCents;
    }

    const amountInDollars = exactAmountCents * 0.01;

    if (isFixedFare) {
        // display price to exact cent
        return '$' + amountInDollars.toFixed(2);
    }
    else {
        // display +- 10% range in dollars
        const minDollars = Math.floor(amountInDollars * 0.9);
        const maxDollars = Math.ceil(amountInDollars * 1.1);

        return `$${minDollars} - $${maxDollars}`;
    }
}

// Check is valid pickup and destination address 
export function IsValidPickupAndDestinationAddress(state: ApplicationState): boolean {
    return !!state.booking.Pickup.Address && !!state.booking.Dropoff.Address;
}