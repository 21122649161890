import * as React from 'react';
import { IconButton, InputAdornment, TextField, Tooltip } from '@mui/material';
import { AddressDropdownList } from './AddressDropdownList';
import { LocationTypes, PlaceSearchInput, GoogleMapsTypesOfRegions } from '../Entities/PlaceSearchEntities';
import { GetGooglePredictions } from '../GoogleApis/GetGooglePlacePredictions';
import { OutlinedInputProps } from '@mui/material';
import { InternalAddressPickerProps } from '../Entities/AddressPickerProps';
import Close from '@mui/icons-material/Close';
import { AddressEntryKind, AddressPickerEntry } from '../Entities/AddressPickerEntry';
import { ComboAddress } from '../Entities/ComboAddress';
import appstore from '../../../appStore';
import { AddressPointDetails } from '../../../Services/AddressPointsContracts';
import StarBorder from '@mui/icons-material/StarBorder';
import Star from '@mui/icons-material/Star';
import { LoadGooglePlaceDetails } from '../../Booking/BookingLocationHelper';
import { AddressDetails } from '../../AddressPoints/Redux/AddressPointsEntities';
import { ConsiderOpeningSaveDialogWithData } from '../ConsiderOpeningSaveDialogWithData';

/**
 * Address picker widget with autocomplete suggestions from Google Maps API.
 */
export const AddressPickerV2: React.FC<InternalAddressPickerProps> = (props) => {

    // whether the <input> element has input focus. This is when the dropdown list will be displayed
    const [isInputFocused, setIsInputFocused] = React.useState(false);

    // raw text typed by the user
    const [inputText, setInputText] = React.useState('');

    // dropdown list items suggested by Google based on the user's input
    const [listItems, setListItems] = React.useState<AddressPickerEntry[]>([]);

    // dropdown list entry picked by the user
    const [selectedValue, setSelectedValue] = React.useState<AddressPickerEntry | null>(null);

    // an entry in the dropdown list that is targeted after keyboard down arrow events
    const [keyboardTargetIndex, setKeyboardTargetIndex] = React.useState<number | null>(null);

    // the inner <input>. For when we need to force the text value
    const inputRef = React.useRef<HTMLInputElement>(null);

    // Apply forced text update pushed from state
    React.useEffect(() => {

        if (props.SpecifiedPlaceText !== undefined) {

            const input = inputRef.current;
            if (input) {
                input.value = props.SpecifiedPlaceText;
            }
        }

    }, [props.SpecifiedPlaceText])

    // Call Google API to populate dropdown list when input text changes
    React.useEffect(() => {
        let isStillMounted = true;

        if (inputText === '') {
            setListItems([]);
            return
        }

        // when a value is selected, it will update the input text.
        // make sure not to trigger a new search from this
        if ((selectedValue != null) && (inputText == ComboAddress.GetPrimaryDisplayText(selectedValue))) {
            return;
        }

        // Also ignore updates caused by forcing the input text
        if (inputText === props.SpecifiedPlaceText) return;

        const request: PlaceSearchInput = {
            RawInputText: inputText,
            LocationType: props.LocationType,
            PreferNearbyTo: props.PreferNearbyTo,

            SuccessCallback: (results) => ReceiveResults(results, isStillMounted),
        };

        GetGooglePredictions(request);

        return () => {
            isStillMounted = false;
        };
    }, [inputText]);

    /**
     * Callback from the place prediction lookup.
     */
    function ReceiveResults(results: google.maps.places.AutocompletePrediction[], isStillMounted: boolean) {

        // callback response after control is unmounted. Don't continue!
        if (!isStillMounted) return;

        const filteredResults = results.filter(IsAddressEntryAllowed); 
        const fullResults = MergeFavouritesWithGoogleData(filteredResults);

        setListItems(fullResults);
        setKeyboardTargetIndex(null);
    }

    const isDropdownShown = ShouldDisplayDropdown();
    const inputCustomisation = GetInputAdornments();

    const shouldShrinkLabel = isInputFocused || (inputText != '') || !!props.SpecifiedPlaceText;

    return (
        <div onKeyDown={OnKeyDown}>
            <TextField
                placeholder={props.PlaceholderText}
                fullWidth={true}
                variant="outlined"
                inputRef={inputRef}
                label={props.LabelText}
                autoFocus={props.AutoFocus}
                error={!!props.IsSelectedAddressInvalid}
                onChange={OnTextInputChanged}
                onBlur={() => OnFocusChanged(false)}
                onFocus={() => OnFocusChanged(true)}
                InputProps={inputCustomisation}
                InputLabelProps={{ shrink: shouldShrinkLabel }}
            />

            {isDropdownShown && (
                <AddressDropdownList
                    InputText={inputText}
                    ListItems={listItems}
                    OnItemSelected={OnListEntrySelected}
                    TargetedRowIndex={keyboardTargetIndex}
                />
            )}
        </div>
    );

    /** We want to figure out if the user is in the middle of editing, purely from state. */
    function ShouldDisplayDropdown(): boolean {

        // empty
        if (inputText === '') return false;

        // value has been selected
        if (selectedValue && (ComboAddress.GetPrimaryDisplayText(selectedValue) == inputText)) return false;

        if (props.SpecifiedPlaceText && (props.SpecifiedPlaceText == inputText)) return false;

        return true;
    }

    /** The icons to render at the start and end of the input text control */
    function GetInputAdornments(): Partial<OutlinedInputProps> {

        const inputCustomisation: Partial<OutlinedInputProps> = {};

        // end adornment changes based on certain properties.
        inputCustomisation.endAdornment = (
            <InputAdornment position="end">
                {ConsiderShowingClearButton()}
                {ConsiderShowingSaveButton()}
            </InputAdornment>
        );

        if (props.StartIconUrl) {
            inputCustomisation.startAdornment = (
                <InputAdornment position="start">
                    <img src={props.StartIconUrl} alt="startIcon"/>
                </InputAdornment>
            );
        }

        return inputCustomisation;
    }

    /** Returns the Clear button component if conditions are satisfied. */
    function ConsiderShowingClearButton(): React.ReactNode {

        if (!props.OnCleared) return;
        if (inputText == '' && !props.SpecifiedPlaceText) return;

        return (
            <Tooltip title="Clear" arrow>
                <IconButton onClick={OnClearClicked} size="large">
                    <Close fontSize="small" />
                </IconButton>
            </Tooltip>
        );
    }

    /** Returns the corresponding save button component if conditions are satisfied. */
    function ConsiderShowingSaveButton(): React.ReactNode {

        if (!props.IncludeFavouriteAddresses) return;
        if (!selectedValue && !props.SpecifiedPlaceText) return;
        if (selectedValue?.Kind === AddressEntryKind.Favourite) {
            return (
                <IconButton size="large">
                    <Star fontSize="small" color="primary" />
                </IconButton>
            );
        }

        return (
            <Tooltip title="Save this address" arrow>
                <IconButton onClick={OnSaveClicked} size="large">
                    <StarBorder fontSize="small" />
                </IconButton>
            </Tooltip>
        );
    }

    /** On click of Save button (star), opens the relevant dialog based on the login status with best effort to prefill address details. */
    async function OnSaveClicked() {
        const addressDetails = await TryMakeAddressDetails();

        ConsiderOpeningSaveDialogWithData(addressDetails);
    }

    /** Try to make AddressDetails from the specified address value. */
    async function TryMakeAddressDetails(): Promise<AddressDetails | null> {
        if (!selectedValue) return null;

        // Only applicable for the addresses selected from the Google maps API
        if (selectedValue.Kind !== AddressEntryKind.GoogleMaps) return null;

        const placeResult = await LoadGooglePlaceDetails(selectedValue.Suggestion.place_id);

        if (!placeResult) return null;
        if (!placeResult.geometry) return null;

        return {
            DisplayText: selectedValue.Suggestion.description,
            GooglePlaceId: selectedValue.Suggestion.place_id,
            GeoLocation: {
                latitude: placeResult.geometry.location.lat(),
                longitude: placeResult.geometry.location.lng()
            }
        }
    }

    /** When the clear button has been clicked. */
    function OnClearClicked() {

        // drop all our state
        setInputText('');
        setListItems([]);
        setSelectedValue(null);

        // notify owner to drop extra state
        props.OnCleared!();

        // give the text box a helping hand
        inputRef.current!.value = '';
    }

    /**
     * Keep track of input focus; it's part of computing the label shrink.
     */
    function OnFocusChanged(newFocusState: boolean) {
        setIsInputFocused(newFocusState);
    }

    /**
     * Can trigger from:
     * 1) User genuinely typing
     * 2) entry selected from dropdown list
     * 3) value forced in from external state
     */
    function OnTextInputChanged(e: React.ChangeEvent<HTMLInputElement>) {
        const value = e.target.value;
        setInputText(value);
        setKeyboardTargetIndex(null);
    }

    /**
     * The user has selected an entry from the list.
     */
    function OnListEntrySelected(result: AddressPickerEntry) {

        setSelectedValue(result);

        const displayText = ComboAddress.GetPrimaryDisplayText(result);
        setInputText(displayText);

        // manually update the text to match
        inputRef.current!.value = displayText;

        props.OnPlaceSelected(result);
    }

    /** 
     *  Determines whether an Autocomplete address entry is valid to use in this picker. 
     *  If it is a strict address, suburb-like entries are removed.
     */
    function IsAddressEntryAllowed(entry: google.maps.places.AutocompletePrediction): boolean {

        if (props.LocationType === LocationTypes.AddressStrict) {
            if (GoogleMapsTypesOfRegions.every(type => entry.types.includes(type))) {
                return false;
            }
        }

        return true;
    }

    /**
     * Get matching favourite addresses only if specified in props and returned a combined data set.
     */
    function MergeFavouritesWithGoogleData(results: google.maps.places.AutocompletePrediction[]): AddressPickerEntry[] {

        const googleEntries = results.map<AddressPickerEntry>(result => ({
            Kind: AddressEntryKind.GoogleMaps,
            Suggestion: result,
        }));

        let allEntries = googleEntries;

        if (props.IncludeFavouriteAddresses) {
            const favourites = GetMatchingFavourites();
            allEntries = [...favourites, ...googleEntries];

            // limit size; prioritise favourites
            if (allEntries.length > 5) {
                allEntries = allEntries.slice(0, 5);
            }
        }

        return allEntries;
    }

    /** Return the user's favourites that match the input text. */
    function GetMatchingFavourites(): AddressPickerEntry[] {

        if (props.LocationType == LocationTypes.CityOrArea) return [];

        const allFavourites = appstore.getState().addressPoints.List;
        const matches = allFavourites.filter(i => DoesFavouriteMatchSearchText(i, inputText));

        return matches.map<AddressPickerEntry>(i => ({
            Kind: AddressEntryKind.Favourite,
            Favourite: i
        }));
    }

    /**
     * I'm making [text] a parameter instead of just using [inputText] since I'm confident we will extract this method.
     */
    function DoesFavouriteMatchSearchText(favourite: AddressPointDetails, text: string): boolean {
        // super simple for now. Make it smarter in future, e.g. only accept matches on the start of word boundaries
        return favourite.Name.toLowerCase().includes(text.toLowerCase()) || favourite.DisplayText.toLowerCase().includes(text.toLowerCase())
    }

    //#region Keyboard Arrow Events

    /**
     * Keyboard event handler for custom keyboard support.
     * Use the down arrow keys to highlight an entry, then enter to pick it.
     */
    function OnKeyDown(event: React.KeyboardEvent<HTMLDivElement>) {

        if (event.key === "ArrowDown") {
            ChangeKeyboardTarget(true);
            event.preventDefault();
        }

        if (event.key === "ArrowUp") {
            ChangeKeyboardTarget(false);
            event.preventDefault();
        }

        if (event.key === "Enter") {
            CommitKeyboardTarget();
            event.preventDefault();
        }
    }

    /**
     * From a keyboard down or up event.
     * Update the keyboard target index accordingly.
     */
    function ChangeKeyboardTarget(downwards: boolean) {

        // nothing to target
        if (listItems.length === 0) return;

        // starting from an untargeted state
        if (keyboardTargetIndex == null) {

            setKeyboardTargetIndex(downwards ? 0 : listItems.length - 1);
        }
        else {
            // moving the target index up or down
            const delta = downwards ? 1 : -1;
            let newIndex = keyboardTargetIndex + delta;

            // wrap
            if (newIndex < 0) newIndex = listItems.length - 1;
            if (newIndex >= listItems.length) newIndex = 0;

            setKeyboardTargetIndex(newIndex);
        }
    }

    /** 
     *  When the user presses the Enter key (after targeting a list item using the down arrow keys).
     *  Select the corresponding entry as if it was clicked.
     */
    function CommitKeyboardTarget() {

        if ((keyboardTargetIndex != null) && (keyboardTargetIndex < listItems.length)) {

            const targetItem = listItems[keyboardTargetIndex];
            OnListEntrySelected(targetItem);
        }
    }

    //#endregion
}