import { Brand, MgmtBrandId } from "../Entities/Brand";
import { ConfigOverride } from "../Entities/ConfigOverride";
import { WellKnownApplicationID } from "../ApplicationID";

/** 
 * Brand-specific overrides to the default config. 
 * The overrides may not exist for each brand (first Partial{}). 
 * An override may only override some of the settings (second Partial{}). 
 */
export const BrandConfig: Partial<Record<Brand, ConfigOverride>> = {};

/** overrides for 13cabs */
BrandConfig[Brand.OneThreeCabs] = {
    Features: {
        GoToOldSite: true,
        PreAuth: true,
        PriceGuarantee: true,
        PriceGuaranteeWithAccount: true,
        LegalDocumentsV2: true,
    }
};

/** overrides for silver service */
BrandConfig[Brand.SilverService] = {
    Values: {
        HomeUrl: "https://silverservice.com.au",
        WebsiteTitle: "Silver Service",
        BrandColour: "#145086",
        BrandName: "Silver Service",
        BrandContactNumber: "133 100",
        DefaultToSilverservice: true,
        InternalApplicationId: WellKnownApplicationID.SilverService,
        Auth0: {
            ClientDomain: "silverservice.au.auth0.com",
            ClientId: "FqCvniU0EPz1MXHBGiSZx5PK2R4gUR78",
            Connection: "13CABS-Public-Users",
            CustomDomain: "auth.silverservice.com.au"
        },
        GoogleAnalyticsKey: "UA-530050-4",
        GoogleTagManagerKey: "GTM-54Z899K",
        AppInsightsKey: "730c6ec7-9dce-4a0e-93ac-3d83ccedecfb",
        MgmtBrandId: MgmtBrandId.SilverService,
        BookingContactNumber: "133 100",
    },
    Features: {
        KeepMobileUsers: true,
        WhatsNew: false,
        GoToOldSite: false,
        ShowDownloadMobileAppButton: false,
        PriceGuarantee: true,
        PriceGuaranteeWithAccount: true,
        LegalDocumentsV2: true,
    }
};

/** overrides for Cabcharge, Auth0 is for environment localdebug & development only */
BrandConfig[Brand.CabCharge] = {
    Values: {
        HomeUrl: "https://plus.cabcharge.com.au/",
        WebsiteTitle: "Cabcharge Concierge",
        BrandColour: "#00968C",
        InternalApplicationId: WellKnownApplicationID.Cabcharge,
        Auth0: {
            ClientDomain: "cabchargedev.au.auth0.com",
            ClientId: "3bArdt6injq0ijb1KWbWFpV8GvCFAaK1",
            CustomDomain: "auth.cc-dev-azure.com",
            Connection: "Username-Password-Authentication"
        },
        BrandName: "Cabcharge",
        BrandContactNumber: null,
        PrivacyUrl: "https://www.cabcharge.com.au/policies/cabcharge-privacy-policy-(pdf).pdf",
        GoogleAnalyticsKey: "UA-175166742-1",
        AppInsightsKey: "730c6ec7-9dce-4a0e-93ac-3d83ccedecfb",
        GoogleTagManagerKey: "GTM-5BZH43R",
        ExternalContactUsUrl: "https://www.cabcharge.com.au/contact-us",
        MgmtBrandId: MgmtBrandId.Cabcharge
    },
    Features: {
        NoSignup: true,
        WhatsNew: false,
        ResetPassword: false,
        ForceLogin: true,
        NoTermsAndConditions: false,
        SingleSignOn: true,
        ForgotPassword: true,
        KeepMobileUsers: true,
        GoToOldSite: false,
        ShowDownloadMobileAppButton: false,
        BookOnAccount: false,
    }
};

/** overrides for Book 360 (general purpose brand) */
BrandConfig[Brand.Book360] = {
    Values: {
        WebsiteTitle: "Book 360 - we'll get you there",
        BrandColour: "#f58200",

        /** Neal confirms to login as Cabcharge for Book 360, but no force-login required */
        InternalApplicationId: WellKnownApplicationID.Cabcharge,
        Auth0: {
            ClientDomain: "cabchargedev.au.auth0.com",
            ClientId: "3bArdt6injq0ijb1KWbWFpV8GvCFAaK1",
            CustomDomain: "auth.cc-dev-azure.com",
            Connection: "Username-Password-Authentication"
        },
        MgmtBrandId: MgmtBrandId.Book360,
        TrackingLinkBaseUrl: "https://invc-book-360-website-dev.azurewebsites.net/b2/"
    },
    Features: {
        BookingApiV2: true,
        MultiTenantLogin: true,
        NoSignup: true,
        GoToOldSite: false,
        CardNotPresentPayment: false,
        ResetPassword: false,
        ShowDownloadMobileAppButton: false,
        TripHistory: true,
        QuickBook: true,
        BookingTemplates: false,
        WhatsNew: false,
        OrderNumber: false
    }
};

BrandConfig[Brand.Tailwind] = {
    Values: {
        WebsiteTitle: "Tailwind Transport - it's a breeze",
        BrandColour: "#3499CC",
        Auth0: null,
        MgmtBrandId: MgmtBrandId.Tailwind,
        BrandContactNumber: "13 wind",
        HomeUrl: null,
        BrandName: "Tailwind",
        TrackingLinkBaseUrl: "https://tailwind.mtidev.net/b2/",
        DefaultLocation: {
            displayName: "Glasgow",
            geoPoint: { latitude: 55.8555367, longitude: -4.3024979 },
            isGeopointServiced: true,
            isStateServiced: true,
            isValid: true,
            stateCode: "scotland",
        },
        AllowedCountryCodes: ["GB"],
        BookingContactNumber: "13 wind",
        DriverConnectNumber: null
    },
    Features: {
        BookingApiV2: true,
        MultiTenantLogin: false,
        NoSignup: true,
        GoToOldSite: false,
        CardNotPresentPayment: false,
        ResetPassword: false,
        ShowDownloadMobileAppButton: false,
        TripHistory: true,
        QuickBook: true,
        BookingTemplates: false,
        WhatsNew: false,
        OrderNumber: false,
        GuestOnly: true,
        LegalDocumentsV2: true
    }
};