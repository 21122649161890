import React, { FC } from 'react';
import { connect } from 'react-redux';
import { Switch } from "@mui/material";
import { ApplicationState } from '../../appState';
import { LoginStatusKind } from '../Authentication/AuthEntities';
import { UpdatePriceGuaranteeStatus } from './PriceGuaranteeToggleHandler';
import { ContentURL, getContentUrl } from '../Utils/ContentURL';
import { Dispatch} from '../Dispatch';
import { DialogKind } from '../Dialog/DialogEntities';
import { IsFixedFareAvailable } from '../Condition/ShouldDisplayFare';

export interface PriceGuaranteeToggleProps {
    LoginStatus: LoginStatusKind;
    IsPriceGuaranteeSelected: boolean; 
    IsFixedFareAvailable: boolean;
}

const OpenModalInfo = () => {
    Dispatch.Dialog.ShowDialog(DialogKind.PriceGuaranteeFAQ);
}

/** Displays the toggle control to switch between the fixed and estimated fare on the vehicle selector. */
const PriceGuaranteeToggle: FC<PriceGuaranteeToggleProps> = (props) => {
    
    return (
        <div className="price-guarantee-toggle-div">
            <div className="on-account-toggle-section price-guarantee-toggle" onClick={e => e.stopPropagation()}>
                {
                    props.IsFixedFareAvailable && 
                    <>
                        <div className="toggle-label-common price-guarantee-toggle-label "> View Price Guarantee </div>
                        <div className="service-info-block" onClick={OpenModalInfo}><img src={getContentUrl(ContentURL.images.info.GreyInfoIcon)} className="service-info-modal" /></div>
                        <Switch 
                            color="secondary"
                            onChange={() => UpdatePriceGuaranteeStatus(props)}
                            checked={props.IsPriceGuaranteeSelected}
                        />
                    </>
                }
            </div>
        </div>
    );
}

function mapStateToProps(state: ApplicationState): PriceGuaranteeToggleProps {
    return {
        LoginStatus: state.authentication.LoginStatus,
        IsPriceGuaranteeSelected: state.condition.IsPriceGuaranteeSelected,
        IsFixedFareAvailable: IsFixedFareAvailable(state),
    }
}

export default connect(mapStateToProps)(PriceGuaranteeToggle);

