import React from 'react';
import { GoogleMap, Marker, Polyline } from 'react-google-maps';
import { GeoPoint } from '../../../modules/Location/Entities';
import { toGoogleLatLng } from '../toGoogleLatLng';
import { GetValues } from '../../../Config/MyAppConfig';
import { GetMyDevice } from "../../../Config/MyAppConfig";
import { DeviceKind } from "../../../Config/Entities/DeviceKind";
import TaxiMarker from '../TaxiMarker';
import { MapMarkerImage, MapMarkers } from '../MapMarkers';
import { GoogleMapProps, MapPurpose } from './GoogleMapProps';
import appstore from '../../../appStore';
import { FeatureFlags } from '../../../Config/FeatureFlags';

/** 
 *  Low level Google Maps component that renders the map and markers.
 *  Note: don't use this class directly in your code; use wrapped versions like BookingWidgetGoogleMap or TaxiTrackingGoogleMap.
 */
export class GoogleMapRaw extends React.Component<GoogleMapProps> {

    // used by derived classes to access the GoogleMap object at runtime, e.g. to manually .SetBounds.
    mapObject: React.RefObject<GoogleMap>;

    constructor(props: GoogleMapProps) {
        super(props);
        this.mapObject = React.createRef<GoogleMap>();
    }

    componentDidMount() {

        if (FeatureFlags.GoogleMapsBetaChannel) {

            const experienceId = appstore.getState().booking.GoogleOdrdTripId;

            if (experienceId) {
                google.maps.Settings.getInstance().experienceIds = [experienceId];
            }
        }
    }

    componentDidUpdate() {

        if (this.props.Purpose === MapPurpose.BookingWidget) {

            if (this.mapObject.current) {
                this.FitBoundsToLocations();
            }
        }
    }

    /** This will override the zoom and make sure the pickup and dropoff are in view. */
    FitBoundsToLocations() {
        const pickup = this.props.pickupLocation;
        const dropoff = this.props.dropoffLocation;

        if (pickup && dropoff) {

            // there must be a better way to do this...
            const mapBounds: google.maps.LatLngBoundsLiteral = {
                north: this.min(pickup.latitude, dropoff.latitude),
                south: this.max(pickup.latitude, dropoff.latitude),
                west: this.min(pickup.longitude, dropoff.longitude),
                east: this.max(pickup.longitude, dropoff.longitude)
            }

            this.mapObject.current!.panToBounds(mapBounds);
        }
    }

    min(left: number, right: number) {
        return left < right ? left : right;
    }

    max(left: number, right: number) {
        return left < right ? right : left;
    }

    render() {
        return (
            <GoogleMap
                ref={this.mapObject}
                center={toGoogleLatLng(this.props.mapCenter)}
                zoom={this.props.zoom}
                options={{ disableDefaultUI: true, zoomControl: GetMyDevice() !== DeviceKind.Phone }} // Mobile device doesn't show zoom button
            >
                {this.renderMarker(this.props.pickupLocation, this.props.PickupMarker)}
                {this.renderMarker(this.props.dropoffLocation, MapMarkers.DropoffLocation)}
                {this.renderTaxiMarker()}

                {this.renderDirections()}
            </GoogleMap>
        );
    }

    /** Renders a map marker if the corresponding location prop is defined. */
    private renderMarker(location: GeoPoint | undefined, markerImage: MapMarkerImage): JSX.Element | null {
        if (!location) return null;

        const icon: google.maps.Icon = {
            url: markerImage.Url,
            anchor: new google.maps.Point(markerImage.TargetPoint.x, markerImage.TargetPoint.y),
        };

        return <Marker position={toGoogleLatLng(location)} icon={icon} />;
    }

    /** Renders a taxi if the corresponding location prop is defined. */
    private renderTaxiMarker(): JSX.Element | null {
        if (!this.props.vehicleLocation) return null;

        return <TaxiMarker taxiLocation={this.props.vehicleLocation} carNumber={this.props.carNumber} />;
    }

    /** Renders the path / snail trail, if it exists in the props. */
    renderDirections(): JSX.Element | null {

        if (!this.props.PlannedRoute) return null;

        return (
            <Polyline
                path={this.props.PlannedRoute}
                options={{ strokeColor: GetValues().BrandColour }}
            />
        );
    }
}