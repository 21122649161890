import appstore from "../../appStore";
import { Dispatch } from "../Dispatch";
import { Api } from "../../Services/Api";
import { MapView } from "../../widgets/NavBar/TabEntities";
import { VehicleOption, DataLoadingStatus } from '../Condition/Redux/ConditionEntities';
import services, { ServiceKind } from '../../utils/service-vehicles/ServiceMetadata';
import { RecentTripButtonKind, BookingTabKind, BookingFormKind } from '../UILogicControl/UILogicControlEntities';
import { ApiGeoPoint } from '../../Services/BookingEntities';
import { GetPlaceIdByAddress, ConvertToPlaceResult, LoadGooglePlaceDetails, ApplyPickupLocationSelection, ApplyDropoffLocationSelection } from './BookingLocationHelper';
import { AsyncUpdateOutcome } from "../Condition/AsyncUpdateOutcome";
import { PaymentCardErrorType, PayDriverOption } from "../Payment/PaymentEntities";
import { SimpleUserProfile } from "../User/ProfileEntitiesV2";
import { TemplateToBookingAccountInfo } from "../BookingTemplate/BookingTemplateEntities";
import { BookingTemplate } from "../BookingTemplate/BookingTemplateEntities";
import { AccountBookingPayload, LocationIndex, PlaceResult } from "./BookingEntities";
import { ShowDialogSimpleErrorMessage } from "../../widgets/general-error-message/ErrorMessagingHelper";
import { WellKnownMessageKind } from "../Utils/ErrorMessages";
import { LogEvent } from '../../utils/LogEvent';
import { PopulateConditionListWithDefaultVehicles } from "../Condition/PopulateConditions";
import { ConvertToPaymentOption } from "../Payment/PaymentHandler";
import { BookingInfo, SelectedBookingDetails } from "../MyBookings/MyBookingEntities";
import { FeatureFlags } from "../../Config/FeatureFlags";
import { ParseAndStoreDropoffContactNumber, UpdateDropoffContactAndName } from "./Parcel/BookingContactUtil";
import { CheckPhone } from "../PhoneNumber/CheckPhone";
import { PreviewFeatureId } from "../Features/FeatureEntities";

/**
 * Populate the pickup and dropoff driver notes
 * Populate the account details
 * Populate the pickup and dropoff address
 * Populate the cab details and estimate fare
 * Populate the pickup and dropoff contact details
 * Select the type of Booking Form (Taxi or Parcel Mode)
 */
export async function RecentTripSelection(recentTripData: SelectedBookingDetails) {

    const BookingDetails = recentTripData.BookingDetails;
       
    ClearExistingDetails();

    PopulateAccountDetails(BookingDetails);
    
    PopulateCabDetails(recentTripData.BookingDetails.CarConditionID);

    // Select the type of Booking Form.
    SetTaxiOrParcelMode();

    if (!FeatureFlags.BookingApiV2) {
        const locationDetails = await PopulateMiscBookingDetails(recentTripData);

        if (locationDetails) {
            await PopulateAddresses(locationDetails, recentTripData);
        }
    }
    else {
        // Driver notes
        if (BookingDetails.NotesToDriver) {

            const notes = CleanDriverNoteText(BookingDetails.NotesToDriver);
            
            Dispatch.Booking.DriverNotes(LocationIndex.Pickup, notes);
        }        

        // No place ID from V2 API.
        const bookingPlaceIds: BookingPlaceIds = {
            PickupPlaceId: null,
            DropoffPlaceId: null
        };

        await PopulateAddresses(bookingPlaceIds, recentTripData);
    }

    // Populate Passenger Name
    PopulatePassengerName(BookingDetails.PassengerName);

    // Populate passenger contact number.
    ParseAndStoreContactNumber(BookingDetails.CustomerPhone);
}

export async function BookingTemplateSelection(selectedTemplate: BookingTemplate) {
    
    // Change to NewBooking Tab
    Dispatch.UILogicControl.OnBookingTabSelectionChange(BookingTabKind.NewBooking);

    // Navigate to MapView
    Dispatch.Tab.SelectItem(MapView);

    // Populate account details
    PopulateAccountDetailsFromExistingSource(selectedTemplate);
    
    // Switch OFF the strict validation mode
    Dispatch.UILogicControl.OnIsStrictValidationModeOnBookingFormChange(false);

    ClearExistingDetails();

    const pickup = selectedTemplate.Locations[0];

    //#region Populate pickup address 

    if (pickup.GoogleMapsPlaceId) {

        await PopulatePickupAddress(pickup.GoogleMapsPlaceId, pickup.DisplayText ?? null);        
    }
    else {
        const { LoadingStatus, FareLoadStatus } = appstore.getState().condition;

        Dispatch.Condition.ConditionDataLoadStatus({ ...LoadingStatus, Status: DataLoadingStatus.Idle });

        Dispatch.Condition.FareLoadStatus({ ...FareLoadStatus, LastInput: null });

        // Populate the condition list with default values, inorder to render the selected vehicle
        PopulateConditionListWithDefaultVehicles();
    }
    
    //#endregion

    // Populate cab details
    PopulateCabDetails(selectedTemplate.Conditions);
    
    // Select the type of Booking Form.
    SetTaxiOrParcelMode();

    //#region  Populate dropoff address 

    const dropoff = selectedTemplate.Locations[1];

    if (dropoff.GoogleMapsPlaceId) {
        PopulateDropoffAddress(dropoff.GoogleMapsPlaceId, dropoff.DisplayText ?? null);
    }
    
    //#endregion

    // #region Driver Notes

    // for backwards compatibility, first check the availability of template.DriverNotes and populate it to PickupNotes field (obsolete implementation). 
    // If null, check the availability of first location's driver notes and assign to pickup notes if not null (new implementation)
    if (selectedTemplate.DriverNotes) {
        Dispatch.Booking.DriverNotes(LocationIndex.Pickup, selectedTemplate.DriverNotes);
    }
    else if (pickup.DriverNotes) {
        Dispatch.Booking.DriverNotes(LocationIndex.Pickup, pickup.DriverNotes);
    }

    // second location of the template is dropoff location (currently)
    if (dropoff.DriverNotes) {
        Dispatch.Booking.DriverNotes(LocationIndex.Dropoff, dropoff.DriverNotes);
    }

    // #endregion
    
    // Populate passenger name
    if (pickup.ContactName) Dispatch.Booking.ContactName(LocationIndex.Pickup, pickup.ContactName);

    // Populate passenger contact number
    ParseAndStoreContactNumber(pickup.ContactPhone);

    if (dropoff.ContactName) Dispatch.Booking.ContactName(LocationIndex.Dropoff, dropoff.ContactName);

    if (dropoff.ContactPhone) ParseAndStoreDropoffContactNumber(dropoff.ContactPhone);

    // Populate payment card
    PopulatePaymentMethod(selectedTemplate.PaymentCardId);

    LogEvent.BookingTemplateSelected(selectedTemplate);
}

/** Clear any existing state before applying a favourite or recent booking. */
function ClearExistingDetails() {
    
    // Inorder to update the store with the latest pickup and dropoff addresses, clear the previously stored addresses
    ClearAddresses();

    Dispatch.Condition.ClearPriceGuarantee();
    Dispatch.Condition.ClearFareEstimate();
    Dispatch.UILogicControl.ClearSatssError();

    // Clear the fare details and set the cab details as Next available
    Dispatch.Condition.SelectVehicle({ Service: services.any });
}

export function ClearAddresses() {

    // Clear Pickup address
    Dispatch.GoogleMap.PickupCleared();
    Dispatch.Booking.ClearAddress(LocationIndex.Pickup);
    Dispatch.UILogicControl.OnDoesPickupInputHaveValueChange(false);
    Dispatch.Booking.ClearFavouriteAddress(LocationIndex.Pickup);
    Dispatch.Booking.ClearDriverNotes(LocationIndex.Pickup);

    // Clear dropoff address
    Dispatch.GoogleMap.DropoffCleared();
    Dispatch.Booking.ClearAddress(LocationIndex.Dropoff);
    Dispatch.UILogicControl.OnDoesDropoffInputHaveValueChange(false);
    Dispatch.Booking.ClearFavouriteAddress(LocationIndex.Dropoff);
    Dispatch.Booking.ClearDriverNotes(LocationIndex.Dropoff);
}

export async function PopulatePickupAddress(placeId: string | null, placeText: string | null) {

    const place = await RecoverPlaceDetailsFromIdOrText(placeId, placeText);

    if (!place) {
        Dispatch.UILogicControl.SetPickupValidity(false);
        return;
    }   
    
    const status = await ApplyPickupLocationSelection(place);
    
    const { LoadingStatus, FareLoadStatus } = appstore.getState().condition;

    // For same pickup suburbId, modify the loading and the fareload status inorder to get latest fare
    if (status === AsyncUpdateOutcome.InputsUnchanged) {
        Dispatch.Condition.ConditionDataLoadStatus({ ...LoadingStatus, Status: DataLoadingStatus.Idle });
        Dispatch.Condition.FareLoadStatus({ ...FareLoadStatus, LastInput: null });
    }

    Dispatch.UILogicControl.OnDoesPickupInputHaveValueChange(true);    
}

export async function PopulateDropoffAddress(placeId: string | null, placeText: string | null) {
   
    const place = await RecoverPlaceDetailsFromIdOrText(placeId, placeText);

    if (!place) {        
        Dispatch.UILogicControl.SetDropoffValidity(false);
        return;
    }

    ApplyDropoffLocationSelection(place);    
    
    Dispatch.UILogicControl.OnDoesDropoffInputHaveValueChange(true);
}

/**
 * The caller should provide either placeId or Text.
 */
async function RecoverPlaceDetailsFromIdOrText(placeId: string | null, placeText: string | null): Promise<PlaceResult | null> {

    // resolve place ID
    if (!placeId) {

        if (!placeText) return null;
        placeId = await GetPlaceIdByAddress(placeText);

        // give up
        if (!placeId) return null;
    }

    const placeResult = await LoadGooglePlaceDetails(placeId);
    if (!placeResult) return null;

    return ConvertToPlaceResult(placeResult, placeText);
}

/**
 * Loads extra details about the existing booking from the API in order to create a copy.
 * This is required because some booking details are not loaded by default, for example the payment method.
 * Only for V1 bookings (Booking API).
 * Returns the Google Maps Place IDs for the pickup and dropoff, if discovered. 
 */
async function PopulateMiscBookingDetails(RecentTripData: SelectedBookingDetails): Promise<BookingPlaceIds | null> {

    var BookingDetails = RecentTripData.BookingDetails;
    // Call the BookingLocation API to fetch driver notes and google placeid
    var serviceResult = await Api.Booking.GetMiscBookingDetails({ bookingId: BookingDetails.BookingID, hashCode: BookingDetails.AccessCode });

    if (!serviceResult.isSuccess) return null;

    const miscDetails = serviceResult.value;
    // pickup
    const pickup = miscDetails.Locations.find(x => x.isPickup === true)!;
    // dropoff
    const dropoff = miscDetails.Locations.find(x => x.isPickup === false)!;

    let pickUpMisc = pickup;
    let dropOffMisc = dropoff;

    // Reverse the addresses when booking on return
    if (RecentTripData.TripType == RecentTripButtonKind.BookReturn) {
        pickUpMisc = dropoff;
        dropOffMisc = pickup;
    } 

    const pickupNotes = CleanDriverNoteText(pickUpMisc.remark);
    Dispatch.Booking.DriverNotes(LocationIndex.Pickup, pickupNotes);

    const dropoffNotes = CleanDriverNoteText(dropOffMisc.remark);
    Dispatch.Booking.DriverNotes(LocationIndex.Dropoff, dropoffNotes);

    UpdateDropoffContactAndName(dropOffMisc.contactDetails.name, dropOffMisc.contactDetails.phoneNumber);

    // payment card
    const cardId = miscDetails.Payment?.CardId;
    PopulatePaymentMethod(cardId);

    const bookingPlaceIds: BookingPlaceIds = {
        PickupPlaceId: pickup.googlePlaceId,
        DropoffPlaceId: dropoff.googlePlaceId,
    };

    return bookingPlaceIds;
}

function PopulateAccountDetails(BookingDetails : BookingInfo) {
    
    // Clear previously selected account
    Dispatch.Booking.ClearAccountDetails();
    
    const { AccountNumber, OrderNumber, ContactName } = BookingDetails;

    const { UserProfile } = appstore.getState().authentication;

    if (!UserProfile) return;

    const accountInfo: TemplateToBookingAccountInfo = { AccountNumber: AccountNumber, 
                                                        FileName: ContactName, 
                                                        OrderNumber: OrderNumber };
    
    ValidateAndPopulateAccountAgainstUserProfile(UserProfile, accountInfo);
}

/**
 * Find the account in user profile from specified account number which is derived from booking history and favourite;
 * And assign into booking payload, if there is any;
 * 
 * This function also validate account related information.
 * Validation rule:
 * https://cabcharge.atlassian.net/wiki/spaces/CA/pages/999260447/Book+from+Favourite
 */
export function ValidateAndPopulateAccountAgainstUserProfile(userProfile : SimpleUserProfile, accountInfo: TemplateToBookingAccountInfo) {

    // Populate the account list
    const accountsList = userProfile.Accounts;

    /**
     * Account toggle was off when the favourite or recent trip was made
     */
    if (!accountInfo.AccountNumber) {
        Dispatch.Booking.SetBookOnAccount(false);
        return;
    }

    /**
     * No account linked to this user, but there is a specified account from booking templates/recents/booking history
     */
    if (accountsList.length === 0) {
        ShowDialogSimpleErrorMessage(WellKnownMessageKind.AccountValidationFailure); 
        return;
    }

    /**
     * New business rule:
     * As long as, there are accounts linked to user profile, account toggle is always on;
     *   1) Found matching account: show account information
     *   2) Specified account not found: show "Please select account".
     */
    const bookingOnAccount = true;

    const accIndex = accountsList.findIndex(x => x.AccountNumber == accountInfo.AccountNumber);
        
    // When booking from recent, populate the account details used previously for booking
    if(accIndex >= 0) {
        const account = accountsList[accIndex];

        const accPayload: AccountBookingPayload = {
            FileNumber: accountInfo.FileName,
            OrderNumber: account.IsOrderNumberRequired && accountInfo.OrderNumber || "",            
            SelectedAccountIndex: accIndex,
            SelectedAccount: account                
        }

        /**
         * Order number has value from booking template/recent/booking history, but not required any more from profile for the same account;
         * Ignore, if IsDVA is true.
         */
        if (!!accountInfo.OrderNumber && !account.IsOrderNumberRequired && !account.IsDVA) ShowDialogSimpleErrorMessage(WellKnownMessageKind.AccountValidationFailure); 

        // Set the index of the account, to be selected in the accounts dropdown
        Dispatch.Booking.AccountDetails(accPayload);
    }

    // Specified account cannot be found in account list linked to user
    Dispatch.UILogicControl.SpecifiedAccountNotFound(accIndex < 0);
    // Set the booking on accounts toggle button
    Dispatch.Booking.SetBookOnAccount(bookingOnAccount);
}

/**
 * Populate the pickup and dropoff address using the location text stored in database
 */
async function PopulateAddresses(bookingPlaceIds: BookingPlaceIds, recentTripData: SelectedBookingDetails) {

    const tripDetail: BookingInfo = recentTripData.BookingDetails;
        
    let pickup: PlaceRecoveryParts = {
        Geopoint: tripDetail.PickupLocation,
        PlaceId: bookingPlaceIds.PickupPlaceId,
        PlaceText: tripDetail.PickupText,
    };

    let dropoff: PlaceRecoveryParts = {
        Geopoint: tripDetail.DropoffLocation ?? null,
        PlaceId: bookingPlaceIds.DropoffPlaceId,
        PlaceText: tripDetail.DropoffText ?? null,
    };

    // Reverse the addresses when booking on return
    if (recentTripData.TripType == RecentTripButtonKind.BookReturn) {

        const temp = pickup;
        pickup = dropoff;
        dropoff = temp;
    }

    Dispatch.UILogicControl.SetPickupValidity(isAddressValid(pickup));
    Dispatch.UILogicControl.SetDropoffValidity(isAddressValid(dropoff));

    // pickup
    if (AddressHasSpecificLocation(pickup)) {
        await PopulatePickupAddress(pickup.PlaceId, pickup.PlaceText);   
    }

    // Populate and save the dropoff address if present
    if (AddressHasSpecificLocation(dropoff)) {
        await PopulateDropoffAddress(dropoff.PlaceId, dropoff.PlaceText);
    }
}

/** Intermediate entity to help us recover a Google Place from other data */
interface PlaceRecoveryParts {
    Geopoint: ApiGeoPoint | null,
    PlaceId: string | null,
    PlaceText: string | null,
}

/**
 * True if the booking location seems have a well defined location.
 */
function AddressHasSpecificLocation(address: PlaceRecoveryParts): boolean {

    if (address.PlaceId) return true;

    // this is testing for a non-zero value too. Booking API generates 0 instead of null.
    if ((address.Geopoint) && (address.Geopoint.Latitude)) return true;

    return false;
}

/** Check if the existing address is valid (can be populated in the input field). */
function isAddressValid(address: PlaceRecoveryParts) {
    if (address.PlaceId) return true;
    if (address.Geopoint) return true;

    // KH: this is existing code and it makes no sense. This represents an empty dropoff address.
    if (address.PlaceText === "To be confirmed") return true;

    return false;
}

/**
 * WARNING: changes required. This probably only supports V1 API.
 * @param CarConditionID
 */
function PopulateCabDetails(CarConditionID: number | undefined) {
    
    const { ConditionList, MaxiTaxiLookUp, SelectedCondition } = appstore.getState().condition;   

    if (ConditionList && ConditionList.length > 0) {

        let index = 0;
    
        // By default, the Next Available taxi is selected
        let selectedVehicle: VehicleOption = {...SelectedCondition, Service: services.any};
        
        /**
         * Converting CarConditionID which is a number to string, as it contains string value
         * Converting string to number, as the ID field in the ConditionList is number
         */
        const carId = CarConditionID && parseInt(CarConditionID.toString());

        // The CarConditionID is empty for the Next Available taxi
        if (carId) {
                                    
            index = ConditionList.findIndex(x => x.ApiVehicle && x.ApiVehicle!.ApiId == carId);
                
            if (index >= 0) {
                selectedVehicle = ConditionList[index];
            }
            else {

                if (MaxiTaxiLookUp) {
                    
                    // Retrieve Maxi taxi details
                    const condition = Object.values(MaxiTaxiLookUp).find(x => x.ApiId === carId);

                    if (condition) {
                                  
                       // Maxi Taxi do not have the Condition property, so can't use the ID to retreive it's details
                       const maxiVehicle = ConditionList.find(x => x.Service.isMaxiTaxi);      

                        if (maxiVehicle) {
                                                        
                            selectedVehicle = {
                                ...selectedVehicle,
                                Service: { ...maxiVehicle.Service, short: condition.Description },
                                ApiVehicle: condition
                            };
                        }                                               
                    }                    
                }
                               
                // Show error message dialog incase of inserviceable vehicle
                if (selectedVehicle.Service === services.any) ShowDialogSimpleErrorMessage(WellKnownMessageKind.InserviceableVehicle);                
            }                      
        }

        // apply selection
        Dispatch.Condition.SelectVehicle(selectedVehicle);
        Dispatch.Condition.CollapseVehicleSelectorUI();
    }             
}

export function ParseAndStoreContactNumber(phoneNumber: string | undefined): void {

    Dispatch.Verification.ClearContactNumberError();

    // Set default country information for empty contact number
    if (!phoneNumber) {
        Dispatch.Booking.ClearContactPhone(LocationIndex.Pickup);
        return;
    }

    const result = CheckPhone.FromRawNumber(phoneNumber);

    if (result.IsValid) {
        Dispatch.Booking.ContactPhone(LocationIndex.Pickup, result.Value);
    }
    else {
        Dispatch.Booking.ClearContactPhone(LocationIndex.Pickup);
    }
}

/**
 * Populate the payment method with the specified payment card 
 * If the card is not found, then display error and default the payment option to "Paying the Driver directly"
 */
function PopulatePaymentMethod(paymentCardId: string | undefined) {

    let paymentOption = PayDriverOption;
    
    // Hide previously generated error message if exist
    Dispatch.Payment.SetError(null);

    if (paymentCardId) {
        
        const cardsList = appstore.getState().payment.AllCards;
        const paymentCard = cardsList.find(card => card.CardId === paymentCardId);

        if (paymentCard) {
            paymentOption = ConvertToPaymentOption(paymentCard);
        } else {
            // Card not found in the list of cards belonging to the user.
            Dispatch.Payment.SetError(PaymentCardErrorType.CardNotFound);
        }
    }

    // If no payment option found in the selected booking, default to 'Paying the Driver directly'
    Dispatch.Booking.PaymentMethod(paymentOption);
}

function PopulatePassengerName(passengerName: string | undefined) {
    if (passengerName) {
        Dispatch.Booking.ContactName(LocationIndex.Pickup, passengerName);
        LogEvent.OnAddingPassengerName();
    }
}

/**
 * Populate account details from booking template or from an old booking (quick book tab or history)
 */
function PopulateAccountDetailsFromExistingSource(BookingTemplate: BookingTemplate) {
    // Clear previously selected account
    Dispatch.Booking.ClearAccountDetails();

    const { UserProfile } = appstore.getState().authentication;

    if (!UserProfile) return;

    const AccountInfo: TemplateToBookingAccountInfo = {
        AccountNumber: BookingTemplate.AccountNumber,
        FileName: "",
        OrderNumber: BookingTemplate.AccountReferenceText
    };

    ValidateAndPopulateAccountAgainstUserProfile(UserProfile, AccountInfo);
}

/** Extract the driver notes entered by the user. i.e.: remove predefined driver notes added automatically to the booking (for account bookings). */
function CleanDriverNoteText(fullNote: string): string {
    // Check for the predefined driver notes 
    const index = fullNote.lastIndexOf("--");

    // Remove the prefined driver notes
    if (index > 0) return fullNote.slice(index + 2).trim();

    return fullNote;
}

/** When the parcel widget is available, set taxi vs parcel mode based on the type of booking that has been loaded. */
function SetTaxiOrParcelMode() {
    const state = appstore.getState();

    if (state.features.EnabledPreviews[PreviewFeatureId.ParcelDeliveryWidget]) {

        const isParcel = state.condition.SelectedCondition.Service.kind === ServiceKind.Parcel;

        const formMode = isParcel ? BookingFormKind.ParcelBooking : BookingFormKind.PassengerBooking;

        Dispatch.UILogicControl.ActiveBookingForm(formMode);
    }
}

/** Best effort at getting Google maps place ID for pickup and dropoff. */
interface BookingPlaceIds {

    /** Placeid for Pickup address */
    PickupPlaceId: string | null,

    /** Placeid for Dropoff address */
    DropoffPlaceId: string | null
}