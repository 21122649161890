import React from 'react';
import { withProps } from "recompose";
import { withGoogleMap, withScriptjs, WithScriptjsProps, WithGoogleMapProps } from 'react-google-maps';
import { GoogleMapRaw } from './GoogleMapRaw';
import { GetGoogleMapsApiUrl } from '../GetGoogleMapsApiUrl';
import { GoogleMapProps } from './GoogleMapProps';

// This attaches [mapElement] and [containerElement] props
const MapWrap = withGoogleMap(GoogleMapRaw);

// this attaches [googleMapURL] and [loadingElement] props
const MapAndScriptWrap = withScriptjs(MapWrap);

// this type has all the new props we add
type PropsToAdd = WithGoogleMapProps & WithScriptjsProps;

// default values for our new props
const propsToAdd: PropsToAdd = {
    mapElement: <div className="google-maps-map" style={{ height: `100%` }} />,
    containerElement: <div className="google-maps-container" />,
    googleMapURL: GetGoogleMapsApiUrl(),
    loadingElement: (<div style={{ height: `100%` }} />),
};

// attach the default values
const propsEnhancer = withProps<PropsToAdd, GoogleMapProps>(propsToAdd);
export const GenericGoogleMap = propsEnhancer(MapAndScriptWrap);